import React, { useState, useRef, useEffect,useContext } from 'react'
import { ApiService } from "../../Components/Services/apiservices";

import DataContext from '../../Components/Elements/eventContext';



let apiServices = new ApiService();

function Reservation() {
	const [calenderDate, setCalenderDate] = useState("")
	const [showLogin, setShowLogin] = useState(false);
	const didMountRef = useRef(true)
	const [settingData, setSettingData] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [defaultImage, setDefaultImage] = useState('')
	const contextValues = useContext(DataContext);

	useEffect(() => {
		if (didMountRef.current) {
			var today = new Date()
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();
			var calender = dd + "/" + mm + "/" + yyyy;
			setCalenderDate(calender)
			// const existingScript = document.getElementById('opentable');

			// if (!existingScript) {
			// 	const script = document.createElement('script');
			// 	script.src = '//www.opentable.co.uk/widget/reservation/loader?rid=153762&type=standard&theme=standard&iframe=true&overlay=false&domain=couk&lang=en-GB';
			// 	script.id = 'opentable';
			// 	document.body.appendChild(script);
			// 	script.onload = () => { 
			// 	  if (callback) callback();
			// 	};
			//   }
			//   if (existingScript && callback) callback();

			// apiServices.settingsdataGetRequest().then(res => {
			// 	if (res.data.status == "success") {
			// 		setImageUrl(res.data.settings_url)
			// 		setSettingData(res.data.sitesettings)
			// 		setDefaultImage(res.data.default_image_baseurl)
			// 	}
			// })
		}
		didMountRef.current = false;
	}, []);
	return (
		<>
		
		{/* <Helmet dangerouslySetInnerHTML={{ __html: <script type='text/javascript' src='//www.opentable.co.uk/widget/reservation/loader?rid=153762&type=standard&theme=standard&iframe=true&overlay=false&domain=couk&lang=en-GB'></script>}}>
		
		</Helmet> */}
			<section className="testimonials">
				<div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/slider/2.jpg" data-overlay-dark="2">
					<div className="container">
						<div className="row">
							<div className="col-md-5 mb-30 mt-30">
								<p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></p>
								<h5>A journey through India's flavors.</h5>
								
								{/* <h5>Each of our guest rooms feature a private bath, wi-fi, cable television and include full breakfast.</h5> */}
								<div className="reservations mb-30">
								<a href={"tel:"+ contextValues.settingData.admin_support_mobile}>
									<div className="icon color-1"><span className="flaticon-call"></span></div>
									<div className="text">
										<p className="color-1">Reservations</p> <a className="color-1" href={"tel:"+ contextValues.settingData.admin_support_mobile}>{contextValues.settingData.admin_support_mobile}</a>
									</div>
									</a>
								</div>
								{/* <div className="reservations mb-30">
                            <div className="icon color-1"><span className="flaticon-call"></span></div>
                            <div className="text">
                                <p className="color-1">Reservations</p> <a className="color-1" href="tel:+44 7485 365962">+44 7485 365962</a>
                            </div>
                        </div> */}
								{/* <p><i className="ti-check"></i><small>Call us, it's toll-free.</small></p> */}
							</div>
							<div className="col-md-5 offset-md-2">
								<div className="booking-box">
									{/* <div className="head-box">
										
										<h4>Table Booking Form</h4>
									</div> */}
									<div className="booking-inner clearfix" id='bookingopentable'>
										<span className="spiralloader" style={{marginLeft:"auto" , marginRight:"auto", display:"block"}}></span>
																
										{/* <form action="#" className="form1 clearfix">
											<div className="row">
												<div className="col-md-12">
													<div className="input1_wrapper">
														<label></label>
														<div className="input1_inner">
															<input type="text" className="form-control input datepicker" value={calenderDate} />
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="select1_wrapper">
														<label></label>
														<div className="select1_inner">
															
															<select className="select2 select">
																<option>9:30 pm</option>
																<option>10:00pm</option>
																<option>10:30pm</option>
															</select>
														</div>
													</div>
												</div>
												<div className="col-md-12">
													<div className="select1_wrapper">
														<label></label>
														<div className="select1_inner">
															<select className="select2 select" style={{ width: "100%" }}>
																<option value="0">Persons</option>
																<option value="1">1</option>
																<option value="2">2</option>
																<option value="3">3</option>
																<option value="4">4</option>
															</select>
														</div>
													</div>
												</div>

												<div className="col-md-12">
													<button type="submit" className="btn-form1-submit mt-15" onClick={() => setShowLogin(true)}>Find a Table</button>
												</div>
											</div>
										</form> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}

export default Reservation