import React, { useEffect,useRef, useState } from 'react'
import Footer from "../../Components/Footer";
import PagesHeader from '../../Components/PagesHeader';
import {useParams} from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';
let apiServices = new ApiService()
function Blogs() {
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const [blogListData , setBlogListData] = useState([])
    const [blogImageUrl , setBlogImageUrl] = useState("")
    const [defaultImageUrl , setDefaultImageUrl] = useState("")
    useEffect(() => {
        if (didMountRef.current) { 
       const dataString = {
      "page_url" : "blogs"  
       }
       apiServices.pagePostRequest(dataString).then(res => {
        if(res.data.status === "success"){
            if(res.data.pageData != null){
          setPageData(res.data.pageData)
    setHeaderImage(res.data.page_header_url)

            }
       }
    })
    if(slug != null){
        const dataString2 = {
            "category_slug" : slug
         }
         apiServices.categorywiseblogsListPostRequest(dataString2).then(res=>{
            setBlogListData(res.data.blogsData)
            setBlogImageUrl(res.data.blog_url)
            setDefaultImageUrl(res.data.default_image_baseurl)
         })   
}
else{
    apiServices.bloglistGetRequest().then(res=>{
        if(res.data.status == 'success'){
           setBlogListData(res.data.blogsData)
           setBlogImageUrl(res.data.blog_url)
           setDefaultImageUrl(res.data.default_image_baseurl)
        }
    })
    }
}
  didMountRef.current = false;
  }, []); 
    return (
      <>

<PagesHeader/>
<div id="cappa-main">
        <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center caption mt-90">
            {pageData.page_sub_header != null ?<h5>{pageData.page_sub_header}</h5>:""}
               {pageData.page_header != null  ? <h1>{pageData.page_header}</h1>:""}
                </div>
            </div>
        </div>
    </div>
    <section className="news section-padding bg-blck">
        <div className="container">
            <div className="row">
                {blogListData.map((value)=>(
                <div className="col-md-4 mb-30">
                    <div className="item">
                            <div className="position-re o-hidden"> <img src={value.blog_image != null ? blogImageUrl + value.blog_image :"/assets/img/defaultimage.png"} alt=""/>
                                <div className="date">
                                   <a > <span>{moment(value.created_at).format('MMM')}</span> <i>{moment(value.created_at).format('DD')}</i> </a>
                                </div>
                            </div>
                            <div className="con"> <span className="category">
                                    <a href={`/blogs/${value.blog_slug}`}>{value.blog_category_name}</a>
                                </span>
                                <h5><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h5>
                            </div>
                        </div>
                </div>))}
             
            </div>
            {/* <div className="row">
                <div className="col-md-12 text-center">
                    
                    <ul className="news-pagination-wrap align-center mb-30 mt-30">
                        <li><a href="news.html#"><i className="ti-angle-left"></i></a></li>
                        <li><a href="news.html#">1</a></li>
                        <li><a href="news.html#" className="active">2</a></li>
                        <li><a href="news.html#">3</a></li>
                        <li><a href="news.html#"><i className="ti-angle-right"></i></a></li>
                    </ul>
                </div>
            </div> */}
        </div>
    </section>
    {/* <section className="news2 section-padding">
        <div className="container">
            <div className="row">
                <div className="col-md-8">
                    <div className="row">
                        {blogListData.map((value) =>(
                        <div className="col-md-12">
                            <div className="item">
                                <div className="post-img">
                                    <a href={`/blogs/${value.blog_slug}`}> <img src={value.blog_image != null ? blogImageUrl + value.blog_image :"/assets/img/defaultimage.png"} alt=""/> </a>
                                    <div className="date">
                                        <a href="#"> <span>{moment(value.created_at).format('MMM')}</span> <i>{moment(value.created_at).format('DD')}</i> </a>
                                    </div>
                                </div>
                                <div className="post-cont"> 
                                   <a href={`/blogs/${value.blog_slug}`}><span className="tag">{value.blog_category_name}</span></a>
                                    <h5><a href={`/blogs/${value.blog_slug}`}><b>{value.blog_name}</b></a></h5>
                                    <p>{value.blog_short_description}</p>
                                    <div className="butn-dark"> <a href={`/blogs/${value.blog_slug}`}><span>Read More</span></a> </div>
                                </div>
                            </div>
                        </div>))}
                       
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="news2-sidebar row">
                        <div className="col-md-12">
                            <div className="widget search">
                                <form>
                                    <input type="text" name="search" placeholder="Type here ..."/>
                                    <button type="submit"><i className="ti-search" aria-hidden="true"></i></button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Recent Posts</h6>
                                </div>
                               
                                <ul className="recent">
                                {blogListData.map((value) =>(
                                    <li>
                                        <div className="thum"> <img src={value.blog_image != null ? blogImageUrl + value.blog_image :"/assets/img/defaultimage.png"} alt=""/> </div> 
                                        <a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a>
                                    </li>
                                     ))}
                                    <li>
                                        <div className="thum"> <img src="/assets/img/news/launch_party.png" alt=""/> </div> 
                                        <a href="#">The Launch Party</a>
                                     </li>
                                    <li>
                                        <div className="thum"> <img src="/assets/img/spa/3.jpg" alt=""/> </div> 
                                        <a href="#">Benefits of Spa Treatments</a>
                                    </li>
                                    <li>
                                        <div className="thum"> <img src="/assets/img/news/2.jpg" alt=""/> </div> 
                                        <a href="#">Absolutley London February Edition 2020</a>
                                    </li>
                                    <li>
                                        <div className="thum"> <img src="/assets/img/slider/7.jpg" alt=""/> </div> 
                                        <a href="#">Retro Lighting Design in The Hotels</a>
                                    </li>
                                    <li>
                                        <div className="thum"> <img src="/assets/img/news/diners.jpg" alt=""/> </div> 
                                        <a href="#">Opentable Diner's Choice Award 2019</a>
                                    </li>
                                </ul>
                               
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Archives</h6>
                                </div>
                                <ul>
                                    <li><a href="#">December 2023</a></li>
                                    <li><a href="#">November 2023</a></li>
                                    <li><a href="#">October 2023</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Categories</h6>
                                </div>
                                <ul>
                                    <li><a href="#"><i className="ti-angle-right"></i>Restaurant</a></li>
                                    <li><a href="#"><i className="ti-angle-right"></i>Hotel Design</a></li>
                                    <li><a href="#"><i className="ti-angle-right"></i>Span Center</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="widget">
                                <div className="widget-title">
                                    <h6>Tags</h6>
                                </div>
                                <ul className="tags">
                                    <li><a href="#">Restaurant</a></li>
                                    <li><a href="#">Hotel</a></li>
                                    <li><a href="#">Spa</a></li>
                                    <li><a href="#">Health Club</a></li>
                                    <li><a href="#">Luxury Hotel</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}


</div>

<Footer/>

</>
)
}

export default Blogs