import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/css/pagination'
import moment from 'moment';
const apiServices = new ApiService()
function Blogs() {
  const didMountRef = useRef(true)
  const [blogData, setBlogData] = useState([])
  const [blogImageUrl, setBlogImageUrl] = useState("")
  const [defaultImage, setDefaultImage] = useState("")
  useEffect(() => {
    if (didMountRef.current) {
      apiServices.featuredblogsGetRequest().then(res => {
        if (res.data.status === "success") {
          setBlogData(res.data.blogsData)
          setBlogImageUrl(res.data.blog_url)
          setDefaultImage(res.data.default_image_baseurl)
        }

      })
    }
    didMountRef.current = false
  })
  return (
    <div>
      {/* <section className="news section-padding bg-blck-light">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-subtitle"><span>Hotel Blog</span></div>
              <div className="section-title"><span>Our News</span></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="owl-carousel owl-theme">
                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/1.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>02</i> </a>
                    </div>
                  </div>
                  <div className="con"> <span className="category">
                    <a href="3">Restaurant</a>
                  </span>
                    <h5><a href="/blogs">Historic restaurant renovated</a></h5>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/2.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>04</i> </a>
                    </div>
                  </div>
                  <div className="con"> <span className="category">
                    <a href="#">Spa</a>
                  </span>
                    <h5><a href="#">Benefits of Spa Treatments</a></h5>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/3.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>06</i> </a>
                    </div>
                  </div>
                  <div className="con"> <span className="category">
                    <a href="#">Bathrooms</a>
                  </span>
                    <h5><a href="#">Hotel Bathroom Collections</a></h5>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/4.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>08</i> </a>
                    </div>
                  </div>
                  <div className="con">
                    <span className="category">
                      <a href="#">Health</a>
                    </span>
                    <h5><a href="#">Weight Loss with Fitness Health Club</a></h5>
                  </div>
                </div>

                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/6.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>08</i> </a>
                    </div>
                  </div>
                  <div className="con"> <span className="category">
                    <a href="#">Design</a>
                  </span>
                    <h5><a href="#">Retro Lighting Design in The Hotels</a></h5>
                  </div>
                </div>
                <div className="item">
                  <div className="position-re o-hidden"> <img src="/assets/img/news/5.jpg" alt="" />
                    <div className="date">
                      <a href="#"> <span>Dec</span> <i>08</i> </a>
                    </div>
                  </div>
                  <div className="con"> <span className="category">
                    <a href="#">Health</a>
                  </span>
                    <h5><a href="#">Benefits of Swimming for Your Health</a></h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="news section-padding bg-blck ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="section-subtitle"><span>Chokhi Dhani's Blog</span></div> */}
              <h2 className="section-title"><span> Blog posts
              </span></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">

              {/* <div className="owl-carousel owl-theme"> */}
              <Swiper

                spaceBetween={12}
                modules={[Pagination, Autoplay]}
                // loop={true}
                autoplay={{ delay: 2000 }}

                breakpoints={{
                  // when window width is >= 640px
                  340: {
                    width: 340,
                    slidesPerView: 0.9,
                  },
                  640: {
                    width: 640,
                    slidesPerView: 0.9,
                  },
                  // when window width is >= 768px
                  768: {
                    width: 768,
                    slidesPerView: 3,
                  },
                  1000: {
                    width: 1000,
                    slidesPerView: 6,
                  },
                  1200: {
                    width: 1200,
                    slidesPerView: 3,
                  },
                }} >
                {blogData.map((value, index) => (
                  <SwiperSlide key={index}>
                    <div className="item">
                      <div className="position-re o-hidden"> <img src={value.blog_image != null ? blogImageUrl + value.blog_image : "/assets/img/defaultimage.png"} alt="" />
                        <div className="date">
                          <a href="#"> <span>{moment(value.created_at).format('MMM')}</span> <i>{moment(value.created_at).format('DD')}</i> </a>
                        </div>
                      </div>
                      <div className="con"> <span className="category">
                        <a href={`/blogs/${value.blog_slug}`}>{value.blog_category_name}</a>
                      </span>
                        <h3><a href={`/blogs/${value.blog_slug}`}>{value.blog_name}</a></h3 >
                      </div>
                    </div>
                  </SwiperSlide>))}



              </Swiper>


              {/* <div className="item">
<div className="position-re o-hidden"> <img src="/assets/img/news/2.jpg" alt=""/>
<div className="date">
<a href="#"> <span>Dec</span> <i>04</i> </a>
</div>
</div>
<div className="con"> <span className="category">
<a href="#">Spa</a>
</span>
<h5><a href="#">Benefits of Spa Treatments</a></h5>
</div>
</div>
<div className="item">
<div className="position-re o-hidden"> <img src="/assets/img/news/3.jpg" alt=""/>
<div className="date">
<a href="#"> <span>Dec</span> <i>06</i> </a>
</div>
</div>
<div className="con"> <span className="category">
<a href="#">Bathrooms</a>
</span>
<h5><a href="#">Hotel Bathroom Collections</a></h5>
</div>
</div> */}
              {/* <div className="item">
<div className="position-re o-hidden"> <img src="/assets/img/news/4.jpg" alt=""/>
<div className="date">
<a href="#"> <span>Dec</span> <i>08</i> </a>
</div>
</div>
<div className="con"> 
<span className="category">
<a href="#">Health</a>
</span>
<h5><a href="#">Weight Loss with Fitness Health Club</a></h5>
</div>
</div>

<div className="item">
<div className="position-re o-hidden"> <img src="/assets/img/news/6.jpg" alt=""/>
<div className="date">
<a href="#"> <span>Dec</span> <i>08</i> </a>
</div>
</div>
<div className="con"> <span className="category">
<a href="#">Design</a>
</span>
<h5><a href="#">Retro Lighting Design in The Hotels</a></h5>
</div>
</div>
<div className="item">
<div className="position-re o-hidden"> <img src="/assets/img/news/5.jpg" alt=""/>
<div className="date">
<a href="#"> <span>Dec</span> <i>08</i> </a>
</div>
</div>
<div className="con"> <span className="category">
<a href="#">Health</a>
</span>
<h5><a href="#">Benefits of Swimming for Your Health</a></h5>
</div>
</div> */}

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Blogs