import React, { useEffect, useRef, useState } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
let apiServices = new ApiService()
function Testimonials() {
  const didMountRef = useRef(true)
  const [testimonialsData, setTestimonialsData] = useState([])
  const [testimonialImageUrl, setTestimonialImageUrl] = useState("")
  const [defaultImage, setDefaultImage] = useState("")
  useEffect(() => {
    if (didMountRef.current) {

      apiServices.testimonialsGetRequest().then(res => {
        if (res.data.status === "success") {
          setTestimonialsData(res.data.testimonials)
          setTestimonialImageUrl(res.data.testimonial_img_url)
          setDefaultImage(res.data.default_image_baseurl)
        }
      })
    }
    didMountRef.current = false
  }, [])
  return (
    <>
      <section className="testimonials">
        <div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/restaurant.jpg" data-overlay-dark="3">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="testimonials-box">
                  <div className="head-box">
                    <h6>Testimonials</h6>
                    <h4>What Client's Say?</h4>
                    <div className="line"></div>
                  </div>

                  {/* <div className="owl-carousel owl-theme"> */}
                  <Swiper
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    spaceBetween={12}
                    breakpoints={{
                      // when window width is >= 640px
                      340: {
                        width: 340,
                        slidesPerView: 0.9,
                      },
                      640: {
                        width: 640,
                        slidesPerView: 0.9,
                      },
                      // when window width is >= 768px
                      768: {
                        width: 768,
                        slidesPerView: 4,
                      },
                      1000: {
                        width: 1000,
                        slidesPerView: 6,
                      },
                      1200: {
                        width: 1200,
                        slidesPerView: 1,
                      },
                    }} >
                    {testimonialsData.map((value) => (
                      <SwiperSlide>
                        <div className="item">
                          <span className="quote"><img src="/assets/img/quot.png" alt="" /></span>
                          {value.testimonial_desc != null ? <p dangerouslySetInnerHTML={{ __html: value.testimonial_desc }}></p> : ""}
                          <div className="info">
                            <div className="author-img"> <img src={value.testimonial_image != null ? testimonialImageUrl + value.testimonial_image : "/assets/img/defaultimage.png"} alt="" /> </div>

                            <div className="cont">
                              {(() => {
                                var html = []
                                for (var i = 0; i < value.testimonial_rating; i++) {
                                  html.push(<span><i className="star-rating"></i></span>)
                                }
                                return html
                              })()}
                              {value.testimonial_name != null ? <h6>{value.testimonial_name}</h6> : ""} {value.testimonial_designation != null ? <span>{value.testimonial_designation}</span> : ""}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>))}

                    {/* <div className="swiper-button-next"></div> */}
                  </Swiper>
                  {/* <div className="item">
                      <span className="quote"><img src="/assets/img/quot.png" alt="" /></span>
                      <p>Hotel dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon sanleo nectan feugiat erat hendrerit necuis ve ante otel inilla duiman at finibus viverra neca the sene on satien the miss drana inc fermen norttito sit space, mus nellentesque habitan.</p>
                      <div className="info">
                        <div className="author-img"> <img src="/assets/img/team/1.jpg" alt="" /> </div>
                        <div className="cont"> <span><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></span>
                          <h6>Nolan White</h6> <span>Guest review</span>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <span className="quote"><img src="/assets/img/quot.png" alt="" /></span>
                      <p>Hotel dapibus asue metus the nec feusiate eraten miss hendreri net ve ante the lemon sanleo nectan feugiat erat hendrerit necuis ve ante otel inilla duiman at finibus viverra neca the sene on satien the miss drana inc fermen norttito sit space, mus nellentesque habitan.</p>
                      <div className="info">
                        <div className="author-img"> <img src="/assets/img/team/5.jpg" alt="" /> </div>
                        <div className="cont"> <span><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></span>
                          <h6>Olivia Martin</h6> <span>Guest review</span>
                        </div>
                      </div>
                    </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Testimonials




